<template>
  <footer class="l-footer" :class="{ 'l-footer--guest': !isLogin }">
    <div class="l-footer-wrap">
      <div class="l-footer-top">
        <div class="l-footer-logo">
          <span>Gacha Online™</span>
        </div>
      </div>
      <div class="l-footer-middle">
        <div class="l-footer-body">
          <nav class="l-footer-nav">
            <NuxtLink to="/"> くじを探す </NuxtLink>
            <NuxtLink v-if="isLogin" to="/shipping/pending">
              引き換え可能
            </NuxtLink>
            <NuxtLink v-if="isLogin" to="/shipping/requested">
              発送依頼済み
            </NuxtLink>
            <NuxtLink v-if="isLogin" to="/shipping/sent"> 発送済み </NuxtLink>
            <NuxtLink v-if="isLogin" to="/mypage/"> マイページ </NuxtLink>
            <NuxtLink
              v-if="isLogin && useRuntimeConfig().env !== 'production'"
              to="/auth/logout"
            >
              ログアウト
            </NuxtLink>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdYfrSHO6-1xF7kezFmtC6VAMzlaIuatZICrxSbXynk4ZIIIA/viewform"
              target="_blank"
              rel="noreferrer"
            >
              お問い合わせ
            </a>
          </nav>
        </div>
        <div class="l-footer-bottom">
          <p>©Gacha Online All Rights Reserved.</p>
          <div class="l-footer-links">
            <NuxtLink to="/terms" class="c-link"> 利用規約 </NuxtLink>
            <NuxtLink to="/commercial-transactions" class="c-link">
              特定商取引法に基づく表記
            </NuxtLink>
            <NuxtLink to="/privacypolicy" class="c-link">
              プライバシーポリシー
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="l-footer-copyright">©Gacha Online All Rights Reserved.</div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const isLogin = computed(() => useUserStore().isLogin)
</script>

<style lang="scss" scoped>
.l-footer--guest {
  @include media-sp {
    height: $sp-guest-footer-height !important;
  }
  .l-footer {
    &-copyright {
      margin-top: 20px;
    }
  }
}
.l-footer {
  background-color: #141414;
  color: #fff;
  text-align: center;
  height: $footer-height;
  @include media-sp {
    height: $sp-footer-height;
  }

  &-wrap {
    padding: 50px 30px 53px;
    max-width: $content-width;
    margin: 0 auto;
    height: 100%;
    @include media-sp {
      padding: 40px 16px 30px;
    }
  }

  &-middle {
    height: fit-content;
    @include media-sp {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0 19px;
    }
  }

  &-body {
    margin-bottom: 40px;
    @include media-sp {
      margin-bottom: 0;
    }
    nav {
      justify-content: flex-start;
      a {
        font-size: 14px;
        font-weight: 700;
      }
      @include media-sp {
        flex-direction: column;
        gap: 18px 0;
        padding-left: 20px;
        min-width: 127px;
      }
    }
  }

  &-bottom {
    p {
      font-size: 11px;
    }
    @include media-sp {
      p {
        display: none;
      }
    }
    .l-footer-links {
      font-size: 12px;
      @include media-sp {
        flex-direction: column;
        gap: 21px 0;
        align-items: flex-start;
      }
    }
  }

  &-copyright {
    font-size: 11px;
    text-align: right;
    margin-top: -12px;
    @include media-pc-tb {
      display: none;
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &-logo {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    height: 22px;
    @include media-sp {
      margin-bottom: 31px;
    }
  }

  &-logo img {
    height: 40px;
    margin-right: 10px;
  }

  &-logo span {
    font-size: 1.5em;
    font-weight: bold;
  }

  &-nav {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
  }

  &-nav a {
    margin-right: 40px;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
    @include media-sp {
      margin-right: 0;
    }
  }

  &-nav a i {
    margin-right: 8px;
  }

  &-nav a:hover {
    text-decoration: underline;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-links {
    display: flex;
    a {
      margin: 0 10px;
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--gachatop {
    @include media-pc {
      display: none;
    }
  }
}

.border-none {
  border-style: none;
}
</style>
